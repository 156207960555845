<template>
  <div class="category-details">
    <div class="title">
      {{
        $store.getters.serviceType === 'service_provider'
          ? $t('enduser.services.servicesCategories')
          : $t('enduser.products.products')
      }}
    </div>

    <header>
      <!--<SelectElement
        class="sort"
        :placeholder="$t('enduser.services.subcategories')"
        background-color="grey"
        :options="$store.getters.subcategoriesOptions"
        v-model="selectedCategory"
        @dropdown-closed="refreshResults"
      />-->
    </header>

    <TableDiv
      v-if="$store.getters.serviceCompanies"
      class="products-table"
      :data="$store.getters.serviceCompaniesTable"
      :column-width="tableSizing"
      :ignore-data="
        $route.name == 'RetailerCategoryDetails' ? ['price_from'] : []
      "
      @row-click="handleServiceClick($event.company.id)"
    >
      <template #header-logo="{ value }">
        {{ $t('enduser.services.logo') }}
      </template>
      <template #header-company="{ value }">
        {{ $t('enduser.services.companyName') }}
      </template>
      <template #header-popular_services="{ value }">
        {{ $t('enduser.products.popularServices') }}
      </template>
      <template #header-rating="{ value }">
        {{ $t('enduser.services.rating') }}
      </template>
      <template #header-price_rating="{ value }">
        {{ $t('enduser.services.priceRating') }}
      </template>
      <template #header-distance="{ value }">
        {{ $t('enduser.services.distance') }}
      </template>
      <template #cell-logo="{ value }">
        <span class="img-holder">
          <img :src="value" style="object-fit: cover" />
        </span>
      </template>
      <template #cell-company="{ value }">
        <div class="link">
          {{ value.companyName }}<br />
          <small>{{ value.description }}</small>
        </div>
      </template>
      <template #cell-popular_services="{ value }">
        <span class="txt-orange">{{ value }}</span>
      </template>
      <template #cell-rating="{ value }">
        <StarRating :stars="5" :active-stars="value" />
      </template>
      <template #cell-price_rating="{ value }">
        <StarRating :stars="5" :active-stars="value" />
      </template>
      <template #cell-price_from="{ value }">
        {{ value | EUR }}
      </template>
    </TableDiv>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api';
import SelectElement from '../../../components/FormControl/SelectElement/SelectElement';
import TableDiv from '../../../components/Table/TableDiv';
import StarRating from '../../../components/Rating/StarRating';
import store from '../../../store';
import router from '../../../router';

export default {
  setup(props) {
    onMounted(() => {
      store.dispatch('fetchServiceCompanies', { cat_id: props.id });
      store.dispatch('fetchSubcategoriesByCategoryIds', props.id);
    });
    const selectedCategory = ref('');
    const tableSizing = [
      { key: 'logo', width: '83px', fixed: true },
      { key: 'company', width: '300px', fixed: false },
      { key: 'popular_services', width: '100px', fixed: false },
      { key: 'rating', width: '100px', fixed: false },
      { key: 'price_rating', width: '100px', fixed: false },
      { key: 'distance', width: 'auto', fixed: false },
    ];

    const handleServiceClick = id => {
      router.push({
        name:
          store.getters.serviceType === 'retailer'
            ? 'RetailerBookAppointment'
            : 'ServiceBookAppointment',
        params: { id },
      });
    };

    watch(
      () => selectedCategory,
      newVal => {
        store.dispatch('fetchSubcategoriesByCategoryIds', {
          cat_id: props.id,
          subcat_ids: newVal,
        });
      }
    );

    return {
      selectedCategory,
      tableSizing,
      handleServiceClick,
    };
  },
  components: {
    StarRating,
    SelectElement,
    TableDiv,
  },
  methods: {
    refreshResults() {
      store.dispatch('fetchServiceCompanies', {
        cat_id: this.id,
        subcat_ids: this.selectedCategory,
      });
    },
  },
  props: {
    id: {
      type: [Number, String],
    },
  },
};
</script>

<style lang="scss">
.category-details {
  .item {
    height: auto;
  }

  header {
    display: flex;
    margin-bottom: 1rem;
  }

  .txt-orange {
    color: $hty-orange;
  }

  .img-holder {
    width: 83px;
    height: 63px;
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .link {
    cursor: pointer;
  }
}
</style>
