var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-details"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.serviceType === 'service_provider' ? _vm.$t('enduser.services.servicesCategories') : _vm.$t('enduser.products.products'))+" ")]),_c('header'),(_vm.$store.getters.serviceCompanies)?_c('TableDiv',{staticClass:"products-table",attrs:{"data":_vm.$store.getters.serviceCompaniesTable,"column-width":_vm.tableSizing,"ignore-data":_vm.$route.name == 'RetailerCategoryDetails' ? ['price_from'] : []},on:{"row-click":function($event){return _vm.handleServiceClick($event.company.id)}},scopedSlots:_vm._u([{key:"header-logo",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.logo'))+" ")]}},{key:"header-company",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.companyName'))+" ")]}},{key:"header-popular_services",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.products.popularServices'))+" ")]}},{key:"header-rating",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.rating'))+" ")]}},{key:"header-price_rating",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.priceRating'))+" ")]}},{key:"header-distance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('enduser.services.distance'))+" ")]}},{key:"cell-logo",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"img-holder"},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":value}})])]}},{key:"cell-company",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"link"},[_vm._v(" "+_vm._s(value.companyName)),_c('br'),_c('small',[_vm._v(_vm._s(value.description))])])]}},{key:"cell-popular_services",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"txt-orange"},[_vm._v(_vm._s(value))])]}},{key:"cell-rating",fn:function(ref){
var value = ref.value;
return [_c('StarRating',{attrs:{"stars":5,"active-stars":value}})]}},{key:"cell-price_rating",fn:function(ref){
var value = ref.value;
return [_c('StarRating',{attrs:{"stars":5,"active-stars":value}})]}},{key:"cell-price_from",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("EUR")(value))+" ")]}}],null,false,828845228)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }